<template>
    <div class="templates-edit" v-if="template">
        <teleport to="#header-nav">
            <CBreadcrumbItem>
                <router-link :to="{ name: 'Templates' }"> Templates</router-link>
            </CBreadcrumbItem>
            <CBreadcrumbItem active>
                <div style="width: 300px;">
                    <span class="text-overflow-ellipsis">{{ template.name }}</span>
                </div>
            </CBreadcrumbItem>
        </teleport>

        <CCard class="min-vh-50 mb-4">
            <CCardBody class="p-0">
                <div class="templates-edit-container">
                    <div id="editor" ref="viewer"></div>
                    <div class="form">
                        <TemplateEditorSettings ref="settings" class="w-100" :template="template"
                                                @save="submit" @cancel="goToTemplateList"/>
                        <TemplateEditorShortcodes v-show="isMergeFieldsListOpen" :visible="isMergeFieldsListOpen"
                                                  class="shortcodes" @close="toggleIsMergeFieldsListOpen"/>
                    </div>
                </div>
            </CCardBody>
        </CCard>
        <FormModalLoader :visible="isSubmittingForm"/>
    </div>
</template>

<script>
import WebViewer from "@pdftron/webviewer";
import config from "@/domain/config";
import Templates from "@/api/v2/endpoints/Templates";
import documentDownload from "@/mixin/documentDownload";
import TemplateEditorSettings from "@/views/Templates/Template/Components/TemplateEditorSettings.vue";
import TemplateEditorShortcodes from "@/views/Templates/Template/Components/TemplateEditorShortcodes.vue";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import FormModalLoader from "@/components/Loaders/FormModalLoader.vue";
import fileExtension from "@/mixin/fileExtension";
import {PDF} from "@/domain/Entities/Upload/extensions";

export default {
    name: 'TemplateEditor',
    components: {FormModalLoader, TemplateEditorShortcodes, TemplateEditorSettings},
    mixins: [documentDownload, fileExtension, apiErrorHandler],
    data() {
        return {
            isSubmittingForm: false,
            isMergeFieldsListOpen: false,
            editorInstance: null,
            templateFile: null,
            templateId: null,
            template: null,
        };
    },
    mounted() {
        this.templateId = this.$route.params.id;
        this.setupPage();
    },
    methods: {
        setupPage() {
            this
                .loadTemplate()
                .then(() => this.downloadTemplate())
                .then(blob => this.setUpEditor(blob));
        },
        loadTemplate() {
            return Templates
                .show(this.templateId, {
                    with_upload: 1,
                    with_task_settings: 1,
                    with_task_settings_recurrence: 1,
                    with_modified_by_user: 'full_name',
                    with_creator_user: 'full_name',
                })
                .then(response => this.template = response.data);
        },
        downloadTemplate() {
            return Templates
                .download(this.templateId)
                .then(response => response.data);
        },
        getEditorInstance(blob) {
            return WebViewer.Iframe({
                initialDoc: URL.createObjectURL(blob),
                licenseKey: config.services.apryse.licenseKey,
                css: `${config.app.baseUrl}/webviewer/style.css`,
                path: `${config.app.baseUrl}/webviewer`,
                extension: this.template.upload.extension,
                filename: this.template.upload.name,
                enableOfficeEditing: true,
            }, this.$refs.viewer);
        },
        setUpEditor(blob) {
            this.getEditorInstance(blob)
                .then(instance => {
                    this.editorInstance = instance;
                    this.setEditorControls();

                    this.editorInstance.Core.documentViewer
                        .addEventListener('annotationsLoaded', () => this.defineEditorMergeFieldsFeature());

                    this.editorInstance.Core.documentViewer
                        .addEventListener('documentLoaded', async () => this.defineEditorMergeFieldsFeature());
                });
        },
        setEditorControls() {
            this.editorInstance.UI.enableFeatures([this.editorInstance.UI.Feature.FilePicker]);
            this.editorInstance.UI.disableElements([
                'fullscreenButton',
                'newDocumentButton',
                'notesPanelToggle',
                'trackChangeOverlayButton',
                'signatureToolGroupButton'
            ]);
        },
        defineEditorMergeFieldsFeature() {
            const defaultHeader = this.editorInstance.UI.getModularHeader('default-top-header')

            defaultHeader.setItems([...defaultHeader.items, this.createMergeFieldsButton()]);
        },
        createMergeFieldsButton() {
            return new this.editorInstance.UI.Components.CustomButton({
                onClick: () => this.toggleIsMergeFieldsListOpen(),
                dataElement: 'customButton',
                title: 'Merge Fields',
                label: '{...}',
            });
        },
        submit(templateData) {
            this.toggleIsSubmittingForm();

            this.updateTemplate(templateData)
                .then(() => this.uploadTemplateFile())
                .then(() => this.toast('success', 'Template has been successfully updated!'))
                .catch(response => {
                    this.handleApiError(response);
                    this.checkUploadFileError();
                })
                .finally(() => this.toggleIsSubmittingForm());
        },
        updateTemplate(templateData) {
            return Templates.update(this.templateId, templateData);
        },
        async uploadTemplateFile() {
            return Templates.upload(this.templateId, await this.defineUploadTemplateFileData());
        },
        async defineUploadTemplateFileData() {
            const editorDocument = this.editorInstance.Core.documentViewer.getDocument();
            const file = await this.getEditorFile(editorDocument);
            const data = new FormData();

            data.append('file', file, editorDocument.getFilename());

            return data;
        },
        async getEditorFile(editorDocument) {
            if (editorDocument.type === PDF) {
                const xfdfString = await this.editorInstance.Core.annotationManager.exportAnnotations();

                return this.convertArrayBufferToPdfBlob(
                    await editorDocument.getFileData({xfdfString})
                );
            }

            return this.convertArrayBufferToDocxBlob(
                await editorDocument.getFileData({documentType: 'office'})
            );
        },
        goToTemplateList() {
            this.$router.push({name: 'TemplatesList'});
        },
        checkUploadFileError() {
            if (this.validationErrors.file) {
                this.toast('success', 'Something wrong happened when saving the document. Please, check if the document is in the right format (doc or docx), try again or contact our support.');
            }
        },
        toggleIsSubmittingForm() {
            this.isSubmittingForm = !this.isSubmittingForm;
        },
        toggleIsMergeFieldsListOpen() {
            this.isMergeFieldsListOpen = !this.isMergeFieldsListOpen;
        },
    }
}
</script>

<style lang="scss" scoped>
.templates-edit-container {
    display: flex;
    height: 75vh;

    #editor {
        flex: 1;
    }

    .form {
        width: 100%;
        max-width: 350px;
        display: flex;
        padding: 5px;
        position: relative;
        background-color: #ffffff;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

        .shortcodes {
            background-color: #ffffff;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }
    }
}
</style>
